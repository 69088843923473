import * as Types from '../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkOrderCheckListTemplateQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type WorkOrderCheckListTemplateQuery = (
  { __typename?: 'Query' }
  & { checkListTemplatesV2: Array<(
    { __typename?: 'CheckListTemplate' }
    & Pick<Types.CheckListTemplate, 'id' | 'name' | 'description'>
  )> }
);


export const WorkOrderCheckListTemplateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"WorkOrderCheckListTemplate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"checkListTemplatesV2"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CheckListTemplateCard_CheckListTemplate"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CheckListTemplateCard_CheckListTemplate"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CheckListTemplate"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}}]} as unknown as DocumentNode;

/**
 * __useWorkOrderCheckListTemplateQuery__
 *
 * To run a query within a React component, call `useWorkOrderCheckListTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkOrderCheckListTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkOrderCheckListTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkOrderCheckListTemplateQuery(baseOptions?: Apollo.QueryHookOptions<WorkOrderCheckListTemplateQuery, WorkOrderCheckListTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkOrderCheckListTemplateQuery, WorkOrderCheckListTemplateQueryVariables>(WorkOrderCheckListTemplateDocument, options);
      }
export function useWorkOrderCheckListTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkOrderCheckListTemplateQuery, WorkOrderCheckListTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkOrderCheckListTemplateQuery, WorkOrderCheckListTemplateQueryVariables>(WorkOrderCheckListTemplateDocument, options);
        }
export function useWorkOrderCheckListTemplateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkOrderCheckListTemplateQuery, WorkOrderCheckListTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkOrderCheckListTemplateQuery, WorkOrderCheckListTemplateQueryVariables>(WorkOrderCheckListTemplateDocument, options);
        }
export type WorkOrderCheckListTemplateQueryHookResult = ReturnType<typeof useWorkOrderCheckListTemplateQuery>;
export type WorkOrderCheckListTemplateLazyQueryHookResult = ReturnType<typeof useWorkOrderCheckListTemplateLazyQuery>;
export type WorkOrderCheckListTemplateSuspenseQueryHookResult = ReturnType<typeof useWorkOrderCheckListTemplateSuspenseQuery>;
export type WorkOrderCheckListTemplateQueryResult = Apollo.QueryResult<WorkOrderCheckListTemplateQuery, WorkOrderCheckListTemplateQueryVariables>;