import ClosableModal from '@/common/components/ClosableModal';
import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import CheckListTemplateCardList from '@/components/checkLists/CheckListTemplateCardList';
import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useModalContext,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useWorkOrderCheckListTemplateSuspenseQuery } from './WorkOrderCheckListFieldPickerModal.generated';

type Props = {
  onCheckListTemplateClicked: (args: { id: number; name: string }) => void;
};

gql`
  query WorkOrderCheckListTemplate {
    checkListTemplatesV2 {
      ...CheckListTemplateCard_CheckListTemplate
    }
  }
`;

const Content = (props: Props) => {
  const { onCheckListTemplateClicked } = props;
  const { onClose } = useModalContext();

  const { t } = useTranslation();
  const {
    data: { checkListTemplatesV2 },
  } = useWorkOrderCheckListTemplateSuspenseQuery();

  const onTemplateClicked = (checkListTemplateId: number) => {
    const checkListTemplate = checkListTemplatesV2.find((c) => c.id === checkListTemplateId);
    if (!checkListTemplate) throw new Error('checkListTemplate is not found');
    onCheckListTemplateClicked(checkListTemplate);
    onClose();
  };

  return (
    <ModalContent>
      <ModalHeader fontSize='md' px={4} py={3}>
        {t('pages.check-list')}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody p={1} borderTop='1px' borderColor='neutral.300'>
        <CheckListTemplateCardList
          checkListTemplates={checkListTemplatesV2}
          onCheckListTemplateClicked={onTemplateClicked}
          maxWith='full'
        />
      </ModalBody>
    </ModalContent>
  );
};

export const useWorkOrderCheckListFieldPickerModal = (): {
  open: (props: Props) => void;
  close: () => void;
  component: React.ReactElement;
} => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [contentProps, setContentProps] = useState<Props | null>(null);

  return {
    open: (props) => {
      setContentProps(props);
      onOpen();
    },
    close: () => {
      onClose();
    },
    component: (
      <ClosableModal
        onClose={onClose}
        size={{ base: 'full', md: 'lg' }}
        scrollBehavior='inside'
        isOpen={isOpen}
      >
        <ModalOverlay />
        {contentProps && (
          <SuspenseWithSpinner>
            <Content {...contentProps} />
          </SuspenseWithSpinner>
        )}
      </ClosableModal>
    ),
  };
};
