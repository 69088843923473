import { gql } from '@apollo/client';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import type { CheckListTemplateCard_CheckListTemplateFragment } from './CheckListTemplateCard.generated';

gql`
  fragment CheckListTemplateCard_CheckListTemplate on CheckListTemplate {
    id
    name
    description
  }
`;

type Props = CheckListTemplateCard_CheckListTemplateFragment & {
  onCheckListTemplateClicked: (checkListTemplateId: number) => void;
  isActive?: boolean;
};

const CheckListTemplate: FC<Props> = (props: Props) => {
  const { id, name, description, onCheckListTemplateClicked, isActive = false } = props;

  return (
    <Box
      p={2}
      borderWidth={1}
      borderLeft='unset'
      borderRight='unset'
      bg={isActive ? 'primary.50' : 'transparent'}
      _hover={{
        bg: isActive ? 'primary.50' : 'neutral.50',
        cursor: 'pointer',
      }}
    >
      <Flex>
        <VStack
          align='stretch'
          width='100%'
          textAlign='left'
          onClick={() => onCheckListTemplateClicked(id)}
        >
          <Text fontSize='md' letterSpacing='wide' color='neutral.800'>
            {name}
          </Text>
          <Text
            fontSize='sm'
            letterSpacing='wide'
            color='neutral.500'
            textOverflow='ellipsis'
            overflow='hidden'
            whiteSpace='nowrap'
          >
            {description}
          </Text>
        </VStack>
      </Flex>
    </Box>
  );
};

export default CheckListTemplate;
