import { Box, HStack, Icon, IconButton, Spacer, Text } from '@chakra-ui/react';
import type { FC } from 'react';
import { FaTrash } from 'react-icons/fa';
import { MdPlayArrow } from 'react-icons/md';

export type CheckListFieldCardType = {
  id?: number;
  name: string;
  templateId: number;
};

export type WorkOrderCheckListFieldCardProps = {
  disabled?: boolean;
  onCheckListClicked: (checkList: CheckListFieldCardType) => void;
  onClickDeleteCheckList: (checkList: CheckListFieldCardType) => void;
  checkList: CheckListFieldCardType;
  isDeleteButtonVisible?: boolean;
  cursor: string;
};

const WorkOrderCheckListFieldCard: FC<WorkOrderCheckListFieldCardProps> = (
  props: WorkOrderCheckListFieldCardProps
) => {
  const {
    disabled = false,
    checkList,
    onCheckListClicked,
    onClickDeleteCheckList,
    isDeleteButtonVisible = true,
    cursor,
  } = props;

  const { name } = checkList;

  return (
    <Box p={2} width='auto' borderWidth={1} borderLeft='unset' borderRight='unset' cursor={cursor}>
      <HStack onClick={() => !disabled && onCheckListClicked(checkList)}>
        <Text fontSize='md' letterSpacing='wide'>
          {name}
        </Text>
        <Spacer />
        {isDeleteButtonVisible ? (
          <IconButton
            disabled={disabled}
            onClick={() => onClickDeleteCheckList(checkList)}
            variant='outline'
            aria-label='remove check list'
            icon={<FaTrash />}
          />
        ) : (
          <Icon as={MdPlayArrow} color='neutral.500' />
        )}
      </HStack>
    </Box>
  );
};

export default WorkOrderCheckListFieldCard;
