import useTranslation from '@/utils/i18n/useTranslation';
import { Box, Text, TextProps } from '@chakra-ui/react';
import { FC } from 'react';
import CheckListTemplateCard from './CheckListTemplateCard';
import type { CheckListTemplateCard_CheckListTemplateFragment } from './CheckListTemplateCard.generated';

type CheckListTemplateCardListProps = {
  checkListTemplates: CheckListTemplateCard_CheckListTemplateFragment[];
  targetCheckListTemplateId?: number;
  onCheckListTemplateClicked: (checkListTemplateId: number) => void;
  titleProps?: TextProps;
  maxWith?: string;
};

// 本来ならここでQueryを発行したいが、このコンポーネントはページでも使っており、
// チェックリストページではテンプレートが選択されていない場合に、詳細画面にリストの最初のテンプレートを描画する上でリストが必要なため、
// まだここでQueryを発行することはできない
const CheckListTemplateCardList: FC<CheckListTemplateCardListProps> = (
  props: CheckListTemplateCardListProps
) => {
  const {
    checkListTemplates,
    onCheckListTemplateClicked,
    titleProps,
    maxWith = '350px',
    targetCheckListTemplateId,
  } = props;

  const { t } = useTranslation();

  return (
    <Box
      minW={{ base: 'full', md: '350px' }}
      maxW={{ base: 'full', md: maxWith }}
      overflowY='auto'
      borderRight='1px'
      borderColor='neutral.200'
      data-testid='check-list-template-card-list-wrapper'
    >
      <Text
        borderColor='neutral.200'
        color='neutral.500'
        fontSize='sm'
        px={2}
        py={1}
        {...titleProps}
      >
        {`${t('sort-by')}: ${t('date.created-date')}`}
      </Text>
      {checkListTemplates.map((checkListTemplate) => (
        <CheckListTemplateCard
          key={checkListTemplate.id}
          {...checkListTemplate}
          onCheckListTemplateClicked={onCheckListTemplateClicked}
          isActive={checkListTemplate.id === targetCheckListTemplateId}
        />
      ))}
    </Box>
  );
};

export default CheckListTemplateCardList;
