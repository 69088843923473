import { ICreateCheckListInput } from '@/modules/checkList';
import { deepCopy } from '@/utils/copy/copy';
import useTranslation from '@/utils/i18n/useTranslation';
import { Button } from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';
import WorkOrderCheckListFieldCard, { CheckListFieldCardType } from './WorkOrderCheckListFieldCard';
import { useWorkOrderCheckListFieldPickerModal } from './WorkOrderCheckListFieldPickerModal';

type Props = {
  workOrderId?: number | undefined;
  isEdit: boolean;
  checkLists: CheckListFieldCardType[];
  onChangeCheckLists: (checkLists: CheckListFieldCardType[]) => void;
  onCheckListClicked?: (checkList: CheckListFieldCardType) => void;
  createCheckList?: (checkList: ICreateCheckListInput) => Promise<number>;
  deleteCheckList?: (checkListId: number) => void;
};

const WorkOrderCheckListField = (props: Props) => {
  const { open: openCheckListTemplateList, component: WorkOrderCheckListFieldPickerModal } =
    useWorkOrderCheckListFieldPickerModal();

  const {
    workOrderId,
    isEdit,
    checkLists,
    onChangeCheckLists,
    onCheckListClicked,
    createCheckList,
    deleteCheckList,
  } = props;

  const { t, t_errors } = useTranslation();

  const canClickCheckList = !isEdit;

  const onClickAddCheckList = () => {
    openCheckListTemplateList({ onCheckListTemplateClicked: handleCheckListTemplateClicked });
  };

  const handleCheckListTemplateClicked = async (checkListTemplate: {
    id: number;
    name: string;
  }) => {
    const checkList: CheckListFieldCardType = {
      templateId: checkListTemplate.id,
      name: checkListTemplate.name,
    };

    if (workOrderId) {
      if (createCheckList === undefined)
        throw new Error(t_errors('item-is-not-defined', { item: 'createCheckList' }));
      const checkListId = await createCheckList(checkList);
      checkList.id = checkListId;
    }

    onChangeCheckLists([...checkLists, checkList]);
  };

  const onClickDeleteCheckList = (checkList: CheckListFieldCardType) => {
    const copiedCheckLists = deepCopy(checkLists);

    if (checkList.id) {
      const index = copiedCheckLists.findIndex((entry) => entry.id === checkList.id);
      copiedCheckLists.splice(index, 1);

      if (workOrderId === undefined) throw new Error('workOrderId is not defined');
      if (deleteCheckList === undefined) throw new Error('deleteCheckList is not defined');

      deleteCheckList(checkList.id);
    } else {
      const index = copiedCheckLists.findIndex(
        (checkList) => checkList.templateId === checkList.templateId
      );
      copiedCheckLists.splice(index, 1);
    }

    onChangeCheckLists(copiedCheckLists);
  };

  const handleCheckListClicked = (checkList: CheckListFieldCardType) => {
    if (!canClickCheckList) return;
    if (onCheckListClicked === undefined) throw new Error('onChangeCheckLists is not defined');
    onCheckListClicked(checkList);
  };

  return (
    <>
      {checkLists.map((checkList, index) => (
        <WorkOrderCheckListFieldCard
          key={index}
          cursor={canClickCheckList ? 'pointer' : 'default'}
          onCheckListClicked={handleCheckListClicked}
          checkList={checkList}
          onClickDeleteCheckList={onClickDeleteCheckList}
          isDeleteButtonVisible={isEdit}
        />
      ))}
      {isEdit && (
        <>
          <Button
            my={1}
            size='sm'
            variant='ghost'
            colorScheme='primary'
            leftIcon={<MdAdd />}
            onClick={onClickAddCheckList}
          >
            {t('actions.item-add')}
          </Button>
          {WorkOrderCheckListFieldPickerModal}
        </>
      )}
    </>
  );
};

export default WorkOrderCheckListField;
